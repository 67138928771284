
.legal-wrapper {
    .main-container {
        background-color: #112b3c;
        border: 1px solid #3a5a71;
        overflow-y: scroll;
    }
}

.input-alike {
    border: 1px solid #0c72a4;
}

.icon-chevron-up {
    display:none;
}